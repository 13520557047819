export default {
  notice:"notice",
  closed:"closed",
  lastArtical:'Last article',
  nextArtical:'Next Article',
  navbar: {
    dashboard: "Dashboard",
    github: "Github",
    logOut: "Log Out",
    profile: "Profile",
    theme: "Theme",
    size: "Global Size"
  },
  login: {
    logIn: "Login",
    phone: "Phone",
    password: "Password",
    keepPwd: "Remember the Password",
    reg: "New User Registration",
    lostPwd: "Forgot Password",
    useManual:"The user manual",
    readTip:"You have read and agree",
  },
  reg: {
    title: "Register",
    username: "Name",
    age: "Age",
    phone: "phone",
    comName: "Co. Name",
    comAddr: "Co. Add.",
    position: "Position",
    experYears: "Work Years",
    email: "Email",
    authCode: "CAPTCHA",
    getCode: "Get Code",
    agree:'You have read and agree to the ',
    explain: "User Agreement・User Privacy"
  },
  forgetPwd: {
    title: "Lost Password",
    pwd: "Please set your password",
    newPwd: "Please set a new password",
    confirm: "Confirm"
  },
  footer: {
    wapLink:'Mobile web page',
    friendlyLink:'Links',
    aboutUs:'About Us',
    link1:'ARKRAY HomePage',
    link2:'Remote Maintenance',
    new:'News and Notice',
    comName:'Aikelai International Trading (Shanghai) Co., Ltd',
    phone:'Contact Us：',
    addr:'Address：Room 202, Building C, Changtai Plaza, No. 3, Lane 2889, Jinke Road, Pudong New District, Shanghai',
    privacyPolicy: "Privacy Policy",
    UseClauses: "Use Clauses",
  },
  navbar: {
    personalData: "Personal Data",
    accountInfo:"Account information",
    myMessage: "My Message",
    trainingTest: "Training test",
    testCenter: "Test Center",
    testRecord:"Test records",
    favorites: "Facorites",
    browserHistory: "Cookies",
    logOut: "Log Out"
  },
  more: "More",
  iconMenu: {
    academic: "Academic Related",
    fault: "Fault Manual",
    maintain: "New Information Release",
    tool: "Tool Use",
    exam: "Training • Assessment",
    datum: "Club Information",
    upload:'Data Download',
    trainingMaterials:'Training examination study materials',
    academicTest:'Academic test',
    primaryTest:'The primary test',
    juniorTest:'Junior Test',
    intermediateTest:'Intermediate test',
    advancedTest:'Advanced test',
  },
  searchContent: "Please enter your search",
  search:"search",
  emptySearch:"Clear History Search",
  searchHistory:'Search Histories',
  searching:'You are searching',
  documentClass:'Document Class',
  InstrumentClass:'Instrument Class',
  my: {
    baseInfo: "Basic Info",
    avatar:"avatar",
    accountInfo: "Account Information",
    amend: "Edit",
    inputName: "Please enter your name",
    inputAge: "Please enter your age",
    inputComName: "Please enter your company name",
    inputComAddr: "Please enter your company address",
    inputPosition: "Please enter your position",
    inputWorkYears: "Please enter your years of experience",
    inputEmail: "Please enter your email address",
    account: "Account",
    password:"password",
    checkPwd: "Please enter the original password",
    min: "The minimum length is 6 characters",
    pwd: "Old Password",
    newPwd: "New Password",
    confirmPwd: "Confirm Password",
    cancle: "Cancel",
    confirm: "Confirm",
    changePwd: "Change Password",
    sign: "Mark as Read",
    noMessage: "No message~",
    seeMore:'See More',
    publishTime:'Publish Time',
    deadline:'Deadline',
    testLogging:'Test Logging',
    noTestLogging:'No record~',
    viewingHours:'Viewing Hours',
    TestRecords:'Test Records',
    points:'Points',
    examDates:'Exam Dates',
    goBack:'Go Back',
    download:'Download',
    startExam:'The Exam Will Begin',
    testTime:'The Test Of Time',
    noFurtherRecords:"No Further Records Are Available",
    startTest:'To Begin Testing',
    through:'Through',
    unqualified:'Unqualified',
    changeInfo:"Modify the data",
    changePwd:"Change the password",
  },
  noData:'No data~',
  home:'Home',
  viewTestResults:'View Test Results',
  returnHome:'Return To The Home Page',
  countdown:'The Countdown',
  answer:'Answer',
  completeSubmission:'Complete Submission',
  testResults:'The test results',
  Tools:'Tool',
  advert:{
    Advertising:"Advertising",
    TopInstructions:'Your trust is very important to us, we know the importance of user information security, we will in accordance with the requirements of laws and regulations, take security measures to protect your user information security and control. As such, are formulated (or "we") the legal notices and privacy policy "(or" statement and policy "for short) and remind you: before using the service, please be sure to read carefully and thoroughly understand the" legal notices and privacy policy ", the confirmation of fully understands and agrees that the use of related products and services. Once you start using it, it will be deemed as acceptance and acceptance of the contents of this Statement and Policy',
    AccountRegistrationUse:'1、Account registration and use',
    TheTermsOfOneFirst:'1.1 You may need to register an account to use the network services provided by Aikelai. After you register successfully, you have the right to log in using the email address, mobile phone number, blog account name and password set by you.',
    TheTermsOfOneSecond:'1.2 The account name and password set by you during registration will be the only valid identification for Aikelai to identify you. It is specially reminded that the account name and password are set by you and you are responsible for keeping. You are legally responsible for all activities and events that occur after you log in with your name and password.',
    TheTermsOfOneThird:'1.3 You should not transfer or lend your account or password to others. If you find that your account has been illegally used by others, you should immediately notify acolai. Akelai will not be liable for any illegal use of your account or password due to hacking or negligence of your custody.',
    TheTermsOfOneFourth:'1.4 As the provider of Aikelai network services, Aikelai can require you to complete real name authentication in accordance with Chinese laws and regulations in order to make you better use of ikelai network services and ensure the security of your account.',
    TheTermsOfOneFifth:'1.5 You must provide true, accurate, complete, legal and valid information to Aikelai when you register and use Aikelai web services, and you are obligated to maintain and update your information in a timely manner. If the information provided by you violates the requirements of this article, you shall be liable for the consequences caused thereby, and ecolor reserves the right to terminate your use of ecolor’s network services.',
    TheTermsOfOneSixth:'1.6 If you are a minor under the age of 18, you should complete registration and use the legal services provided by Aikelai with the participation of your parent or legal guardian.',
    AccountRegistrationUseTwo:'2、Account registration and use',
  },
  startPreview:'Start Preview',
  describe:'Describe',
  regInstruct:{
    RegistrationForNewUsers:'Registration instructions for new users',
    
  },
  useClause:{
    useTerms:'Terms of use',

  },
  inputAnswer:'Please enter your answer',
  lazyFetch:'Seconds after getting',
  getSMS:'Get SMS',
  examMsgStart:'The exam will begin',
  examMsgTesting:'To begin testing',
  slideTip:'Sliding View',
  title:"Welcome to arkray after sale service website",
  scoreTip:'In the grade',
  agreeTip:"Agree to the above"
};
