export default {
  notice:"公告",
  closed:"关闭",
  lastArtical:'上一篇文章',
  nextArtical:'下一篇文章',
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    logIn: '登录',
    phone: '手机号',
    password: '密码',
    keepPwd:'记住密码',
    reg:'新用户注册',
    lostPwd:'忘记密码',
    useManual:"用户手册",
    readTip:"您已阅读并同意",
  },
  reg:{
    title:'注册',
    username:'姓名',
    age:'年龄',
    phone:'联系方式',
    comName:'公司名称',
    comAddr:'公司地址',
    position:'职位',
    experYears:'经验年数',
    email:'邮箱',
    authCode:'验证码',
    getCode:'获取验证码',
    agree:'您已阅读并同意',
    explain:'用户协议・用户隐私'
  },
  forgetPwd:{
     title:'忘记密码',
     pwd:'请设置密码',
     newPwd:'请设置新密码',
     confirm:'确认'
  },
  footer:{
    wapLink:'手机版网页',
    friendlyLink:'友情链接',
    aboutUs:'关于我们',
    link1:'ARKRAY主页',
    link2:'远程维护',
    privacyPolicy:'隐私说明',
    UseClauses:'使用条款',
    new:'新闻公告',
    comName:'爱科来国际贸易（上海）有限公司',
    phone:'电话：',
    addr:'地址：上海市浦东新区金科路2889弄3号 长泰广场 C座202室'
   },
   navbar: {
    personalData:'个人资料',
    accountInfo:"账号信息",
    myMessage:'我的消息',
    trainingTest:'培训测试',
    testCenter:'考试中心',
    testRecord:"考试记录",
    favorites:'收藏夹',
    browserHistory:'浏览记录',
    logOut: '退出登录'
  },
  more:'更多',
  iconMenu:{
    academic:'学术相关',
    fault:'故障手册',
    maintain:'新信息发布',
    tool:'工具使用',
    exam:'培训•考核',
    datum:'面向社内资料',
    upload:'资料下载',
    trainingMaterials:'培训考试学习资料',
    academicTest:'学术测试',
    primaryTest:'初级考试',
    juniorTest:'初中级考试',
    intermediateTest:'中级考试',
    advancedTest:'高级考试',
  },
  searchContent:'请输入搜索内容',
  search:"搜索",
  emptySearch:"清空历史搜索",
  searchHistory:'历史搜索',
  searching:'您正在搜索',
  documentClass:'文档类',
  InstrumentClass:'仪器类',
  my:{
    baseInfo:'基本信息',
    avatar:"头像",
    accountInfo:'账号信息',
    amend:'修改',
    inputName:'请输入您的姓名',
    inputAge:'请输入您的年龄',
    inputComName:'请输入您的公司名称',
    inputComAddr:'请输入您的公司地址',
    inputPosition:'请输入您的职位',
    inputWorkYears:'请输入您的经验年数',
    inputEmail:'请输入您的电子邮箱',
    account:'账号',
    password:"密码",
    checkPwd:'请输入原密码',
    min:'最小长度为6个字符',
    pwd:'原密码',
    newPwd:'新密码',
    confirmPwd:'确认新密码',
    cancle:'取 消',
    confirm:'确 定',
    changePwd:'修改密码',
    sign:'标记为已读',
    noMessage:'暂无消息~',
    seeMore:'查看更多',
    publishTime:'发布时间',
    deadline:'截止时间',
    testLogging:'测试记录',
    noTestLogging:'暂无记录~',
    viewingHours:'浏览时长',
    TestRecords:'考试记录',
    points:'分',
    examDates:'考试日期',
    goBack:'返回',
    download:'下载',
    startExam:'立即考试',
    testTime:'测试时间',
    noFurtherRecords:"暂无更多记录",
    startTest:'开始测试',
    through:'通过',
    unqualified:'不合格',
    changeInfo:"修改资料",
    changePwd:"修改密码",
  },
  noData:'暂无资料~',
  home:'首页',
  viewTestResults:'查看考试结果',
  returnHome:'返回首页',
  countdown:'倒计时',
  answer:'解答',
  completeSubmission:'完成提交',
  testResults:'测试结果',
  Tools:'工具',
  advert:{
    Advertising:"隐私说明",
    TopInstructions:'您的信任对我们非常重要，我们深知用户信息安全的重要性，我们将按照法律法规要求，采取安全保护措施，保护您的用户信息安全可控。鉴此，（或简称“我们”）制定本《法律声明及隐私权政策》（以下或简称“声明和政策”）并提醒您：在使用各项服务前，请您务必仔细阅读并透彻理解本《法律声明和隐私权政策》，在确认充分理解并同意后方使用相关产品和服务。一旦您开始使用，将被视为对本声明和政策内容的接受和认可。',
    AccountRegistrationUse:'一、账号的注册和使用',
    TheTermsOfOneFirst:'1.1 您在使用爱科来提供的网络服务时可能需要注册一个账号，当您注册成功后，您有权使用您设置或确认的邮箱、手机号码、博客账户名称及您设置的密码登录。',
    TheTermsOfOneSecond:'1.2 您注册时所设置的账户名称和密码将作为爱科来识别您身份的唯一有效标识，特别提醒该账户名称和密码为您自行设置并由您负责保管；您应当对以您名称和密码登录后所进行的一切活动和事件负法律责任。',
    TheTermsOfOneThird:'1.3 您不应将帐号、密码转让或出借予他人使用。如您发现帐号遭他人非法使用，应立即通知爱科来。因黑客行为或您的保管疏忽导致帐号、密码遭他人非法使用，爱科来不承担任何责任。',
    TheTermsOfOneFourth:'1.4 作为爱科来网络服务的提供者，为使您更好地使用爱科来的各项网络服务，保障您的账号安全，爱科来可要求您按照我国法律规定完成实名认证。',
    TheTermsOfOneFifth:'1.5 您在注册及使用爱科来网络服务时，必须向爱科来提供真实、准确、完整、合法有效的资料，且有义务维持并及时更新您的资料。如果您提供过的资料违反本条要求，您需承担因此引起的相应责任及后果，并且爱科来保留终止您使用爱科来各项网络服务的权利。',
    TheTermsOfOneSixth:'1.6 若您是18周岁以下的未成年人，您应在您的家长或法定监护人参与下完成注册及使用爱科来提供的法律服务。',
    AccountRegistrationUseTwo:'二、账号的注册和使用',
  },
  startPreview:'开始预览',
  describe:'描述',
  regInstruct:{
    RegistrationForNewUsers:'新用户注册说明',

  },
  useClause:{
    useTerms:'使用条款',

  },
  inputAnswer:'请输入您的答案',
  lazyFetch:"s后获取",
  getSMS:"获取短信",
  examMsgStart:'开始考试',
  examMsgTesting:'开始测试',
  slideTip:'可滑动查看',
  title:"欢迎来到爱科来售后服务网站",
  scoreTip:'批改中',
  agreeTip:"同意以上内容"
}
